<!-- Componente de Búsqueda múltiple -->

<template>
  <div class="uiMultiple"> 
    <div style="display:flex">

      <!-- Finder -->      
      <ctrlF
        style="width:100%"                       
        :ct="ctrl"
        :label="$label"
        :fn="fn"
        :finder="finderView"
        :tipoView="tipoView"        
        :ctView="ctView"
        :menu="items"
        :edicion="!disabled || (ctrlAsoc[2]== '0' || ctrlAsoc[2]== '')"
        @change="$emit('change', $event)"
        @clear="$emit('clear')"
        @onMenu="clickMenu($event)">            
      </ctrlF>                  
      
    </div>   
  </div>
</template>



<script>
    
  export default {    
    props: {    
      ctrl: { type: Array, default:()=> []},
      fn: { type:Function, default: null },
      ct: { type: Object, default:null },     
      ctView: { type: Object, default:()=>{} },
      disabled: { type:Boolean, default:false }   
    },

    data() {
      return {                
        items: this.ctrl[4].comp.source
      };
    },



    methods: {
      
      // obtengo el schema del finder a utilizar en el ctrlF (funerarias, miscelaneas, ...)
      // según el tipo de servicio seleccionado
      getSource(indexServ) {            
        let item= this.items.find(elem=> elem.id== indexServ);   
        
        if (item== undefined) return {};        
        return item;
      },


      // entra al pulsar en el preppend icon del menú
      // actualiza control asociado según la opción seleccionada y blanquea el control principal
      clickMenu(item) {
        if (this.ct[this.ctrl[4].comp.f2][2]== item.id) return;

        // guardo el id del item seleccionado como value del control asociado
        this.$set(this.ct[this.ctrl[4].comp.f2], 2, item.id);

        // actualizo datos en control principal
        this.$set(this.ctrl, 2, "");
        this.$set(this.ctrl[4].comp, 'valueAux', "");
      }

    },


    computed:{

      // control asociado (indicado en propiedad 'f2' del schema)
      ctrlAsoc: {
        get() {
          return this.ct[this.ctrl[4].comp.f2];
        },

        set(val) {
          this.$set(this.ct[this.ctrl[4].comp.f2], 2, val);
        }
      },


      // nombre del componente finder a cargar
      finderView() {
        return this.getSource(this.ctrlAsoc[2]).finder;       
      },


      // tipo de vista del componente finder (FC, FCV, ...)
      tipoView() {
        return this.getSource(this.ctrlAsoc[2]).tipoView;
      },


      $label() {  
        let item= this.getSource(this.ctrlAsoc[2]);
        if (!Object.keys(item).length) return '';
        return item.name;
      }

    },



  };
</script>


